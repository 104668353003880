.ms-DetailsRow-fields {
    border-bottom: 1px solid #e8e8e8;
}

.ms-DetailsList-headerWrapper {
    border-bottom: 2px solid #bfbfbf;
}

#loadMore {
    background-color: #e8e8e8;
    margin-bottom: 20px;
    padding: 10px;
    text-align: center;
}

    #loadMore > a {
        font-size: 0.75rem;
        text-decoration: none;
        color: black;
    }

.documentClass {
    border: 1px solid #a4c1fb;
    padding: 3px 10px;
    background-color: #e3ecfd;
}

    .documentClass + .documentClass {
        margin-left: 5px;
    }

.DetailsRow {
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
}

.protocolloLabels {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
}

#btnLoadMore {
cursor:pointer;
}

.readonlyModalFields {
border:none;
}