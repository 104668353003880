a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.currentSection {
    border-bottom: 3px solid #0078d4;
}
li.nav-item {
    margin-left: 8px;
    margin-right: 8px;
}

.protocollo-ch {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    font-size: 0.7rem;
    background-color: #ff3a3a;
    padding: 3px 3px 3px 3px;
    text-align: center;
    font-weight: 700;
    color: white;
    margin-left: 3px;
    position: absolute;
}

.protocollo-it {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    font-size: 0.7rem;
    background-color: #318800;
    padding: 3px 3px 3px 3px;
    text-align: center;
    font-weight: 700;
    color: white;
    margin-left: 3px;
    position: absolute;
}